import React from "react"
import styled from "styled-components"

import Layout from "../components/layouts/Layout"

import { Slider, ContactSection } from "../components/elements"
import {
  SEO,
  Section,
  AlignCenter,
  Margin,
  Color,
  Media,
  ButtonLink,
  SectionSmall,
} from "../components/utilities"
import { lighten } from "polished"

import pool1 from "../images/home1.jpg"
import pool2 from "../images/donut.jpg"
import slide from "../images/slide.jpg"

const IndexGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 280px 280px;
  grid-gap: 1rem;

  ${Media.below.desktop`
    grid-template-rows: 320px 320px;
  `}

  ${Media.below.tablet`
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  `}
`

const GridContainer = styled.div`
  position: relative;
`

const ImageBlock = styled.div`
  background-image: ${props => `url(${props.image})`};
  background-position: center center;
  background-size: cover;

  ${Media.below.tablet`
    height: 200px;
  `}
`

const IndexBlock = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  background: ${lighten(0.4, Color.primary)};
  color: ${Color.grey};
  padding: 2rem;
  line-height: 150%;
  text-align: center;

  h4 {
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 24px;
  }

  ${ButtonLink} {
    margin-top: 1rem;
  }
`

const slides = [
  {
    image: slide,
    content: () => (
      <>
        <h2>Pool Maintenance</h2>
        <p>
          We pride ourselves on keeping maintenance to a minimum so you are
          spending more time poolside with family and friends.
        </p>
        <ButtonLink to="/contact-us/" modifiers={["secondary"]}>
          Schedule Your Maintenance Call
        </ButtonLink>
      </>
    ),
  },
]

const IndexGridSection = () => (
  <GridContainer>
    <IndexGrid>
      <ImageBlock image={pool1} />
      <IndexBlock>
        <SectionSmall>
          <h4>Pool Builds</h4>
          <p>
            We build swimming pools that deliver superior design and value. We
            partner with only the highest quality subcontractors who share the
            common quality and pride in their work.
          </p>
          <ButtonLink to="/pool-build/" modifiers={["secondary"]}>
            Build A Pool
          </ButtonLink>
        </SectionSmall>
      </IndexBlock>
      <IndexBlock>
        <div>
          <SectionSmall>
            <h4>Pool Services</h4>
            <p>
              We provide quality services to keep your pool looking and
              functioning at its highest levels.
            </p>
            <ButtonLink to="/pool-service/" modifiers={["secondary"]}>
              Our Pool Service
            </ButtonLink>
          </SectionSmall>
        </div>
      </IndexBlock>
      <ImageBlock image={pool2} />
    </IndexGrid>
  </GridContainer>
)

const IndexPage = () => (
  <Layout>
    <SEO
      title="Pool Design & Construction Ohio"
      description="Custom luxury pool design and construction with reliable and exceptional quality. Pool contractors Perrysburg and Northwest Ohio"
    />
    <Slider slides={slides} />
    <Section>
      <AlignCenter>
        <SectionSmall>
          <h1>Hunt Pools &amp; Service</h1>
          <p>
            Hunt Pools &amp; Service is Northwest Ohio's premier swimming pool
            builder. We build unmatched quality in-ground swimming pools
            designed for fun, exercise, relaxation and quality family time.
            Proudly serving Toledo, Sylvania, Maumee, Perrysburg, Whitehouse,
            Monclova, Holland and surrounding cities.
          </p>
        </SectionSmall>
      </AlignCenter>
    </Section>

    <IndexGridSection />

    <Section modifiers="blue">
      <AlignCenter>
        <h2>Why Choose Hunt Pools &amp; Service</h2>
      </AlignCenter>
      <SectionSmall>
        <AlignCenter>
          <p>
            We have a great commitment to excellence, reliability, integrity,
            quality, value and unparalleled customer service. We pride ourselves
            on making your experiences fun and worry free. Pools are FUN... We
            want to keep it that way
          </p>
        </AlignCenter>
        <Margin modifiers={["mt2"]}>
          <AlignCenter>
            <ButtonLink to="/about-us/" modifiers={["secondary"]}>
              About Hunt Pools and Service
            </ButtonLink>
          </AlignCenter>
        </Margin>
      </SectionSmall>
    </Section>
    <ContactSection />
  </Layout>
)

export default IndexPage
